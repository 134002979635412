import styled, { css } from 'styled-components'
import { Div, Button } from '@konsys-ui-custom'
import { theme, media, screenSize } from '../../styles/variables'

export const QuotationContainer = styled(Div)`
  position: relative;
  background-color: ${theme.color.gray10};
  height: 100vh;
  overflow: auto;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 148px;
    background-image: linear-gradient(to bottom right, ${theme?.color?.primaryColor}, ${theme?.color?.secondaryColor});
    z-index: 2;
  }
`

export const Container = styled.div`
  padding: 48px 0;
  margin: 0 16px;
  position: relative;
  z-index: 3;
  > h3 {
    color: white;
    font-family: kanit;
    font-weight: 500;
  }
  ${media.laptop`
    width: ${`${screenSize.tablet}px`};
    margin: 0 auto;
  `}
`

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:not(:first-child) {
    margin-top: 16px;
  }
  ${media.tablet`
    flex-direction: row;
    > div:not(:first-child) {
      margin-top: 0;
    }
  `}
`

export const CollapseButton = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #4A84EC, #3677EA);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > i {
    transition: all 0.3s ease;
  }
  ${(props) => props.rotate && css`
    > i {
      transform: rotate(180deg);
    }
  `}
`

export const QuotationCollapseDetailContainer = styled.div`
  border-top: 1px solid rgba(82, 120, 160, 0.5);
  margin: 16px 0 0;
  padding: 20px 0 0;
`

export const QuotationDetailConainer = styled.div`
  margin-top: 7px;
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 6px;
  position: relative;
  max-height: 1000px;
  &:before {
    content: '';
    position: absolute;
    top: 48px;
    left: -2px;
    right: -2px;
    bottom: 0;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-color: ${theme.color.gray40};
    z-index: -1;
  }
  ${(props) => !props.collapse && css`
    height: auto;
    max-height: 200px;
    > ${QuotationCollapseDetailContainer} {
      display: none;
    }
  `}
`

export const CheckboxLabel = styled.label`
  display: block;
  border-radius: 6px;
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 0 8px ${theme.color.gray10};
  position: relative;
  max-height: 500px;
  transition: max-height 0.3s ease-in-out;
  /* overflow: hidden; */
  cursor: pointer;
  > div {
    position: relative;
    /* z-index: 2; */
  }
  .icon-arrow {
    transition: all 0.3s ease-in-out;
  }
  .check-icon {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
  }
  ${(props) => props.isCheck && css`
    max-height: 1000px;
    border: 1px solid ${props.color || 'rgba(82, 120, 160, 0.8)'};
    ${props.color
  ? css`
      background-image: linear-gradient(110deg, white, white, white, white, ${props.color}20);
    `
  : css`
      background-image: linear-gradient(110deg, white, white, white, white, rgba(82, 120, 160, 0.2));
    `}
    .icon-arrow {
      transform: rotate(180deg);
    }
    .check-icon {
      &:before {
        content: '';
        font-family: "Font Awesome 5 Pro";
        font-weight: 100;
        font-size: 150px;
        line-height: 0.8;
        position: absolute;
        right: -24px;
        bottom: 0;
        opacity: 0.8;
        font-size: 100px;
        right: -8px;
        bottom: 4px;
        color: white;
        transform: rotate(-4deg);
        z-index: 1;
      }
    }
  `}
  .radio-input & {
    border: 2px solid red;
    display: none;
  }
`

export const StepList = styled.div`
  border-bottom: 1px solid ${theme.color.gray40};
  margin: 8px 0 24px;
  display: flex;
  position: relative;
  &:after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 66.66%;
    background-color: ${theme.color.tertiaryColor};
    transition: right 0.3s ease-in-out;
    ${(props) => props.current && css`
      right: ${props.current === 1 ? '66.66%' : props.current === 2 ? '33.33%' : 0};
      ${!props.isShowPayment && css`
        right: ${props.current === 1 ? '50%' : '0'};
      `}
    `}
  }
  ${media.laptop`
    margin: 20px 0 24px;
    padding: 0 4px;
  `}
`

const activeStep = css`
  color: white;
  background-image: linear-gradient(to bottom, #4A84EC, #3677EA);
  box-shadow: none;
  > span {
    color: white;
  }
`

export const StepItem = styled.div`
  transition: flex-grow 0.3s ease;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0;
  > div {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    background-color: white;
    box-shadow: 0 2px 2px ${theme.color.veryLightBlue};
  }
  > span {
    display: none;
  }
  ${(props) => props.current && css`
    flex-grow: 1;
    justify-content: flex-start;
    > div {
      ${activeStep};
    }
    > span {
      display: inline-block;
    }
  `}
  ${(props) => props.active && css`
    flex-grow: 0;
    > div {
      ${activeStep};
      > span {
        font-family: "Font Awesome 5 Pro";
        color: white;
      }
    }
    > span {
      display: none;
    }
  `}
`

export const GradientButton = styled(Button)`
  background-image: linear-gradient(to right, ${theme.color.primaryColor}, ${theme.color.secondaryColor});
  border: 1px solid ${theme.color.tertiaryColor} !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    color: white !important;
    opacity: 0.1;
    > i {
      color: white !important;
    }
  }
  ${(props) => props.lastStep && css`
    background-image: linear-gradient(to right, #488F35, #4DB433);
    border: 1px solid #4DB433 !important;
  `}
`

export const LabelContainer = styled.div`
  > input {
    display: none;
  }
`

export const LabelUploadButton = styled.label`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${theme.color.primaryColor};
  padding: 4px 16px;
  color: ${theme.color.primaryColor};
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  ${(props) => props.disabled && css`
    opacity: 0.1;
  `}
`

export const ModalContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  ${(props) => props.open && css`
    display: block;
  `}
`

export const LogoBg = styled.div`
  height: 31px;
  width: 31px;
  border-radius: 20px;
  background-color: ${theme.color.gray10};
  ${(props) => props.imgUrl && css`
    background-image: url(${props.imgUrl});
    background-size: cover;
  `}
`

export const LineBreakLot = styled.div`
  margin: 0 -16px;
  border-top: 2px dashed rgba(82, 120, 160, 0.5);
`

export const LinkDownload = styled.a`
  display: inline-block;
  > p {
    color: ${theme.color.primaryColor};
    text-decoration: underline !important;
    transition: all 0.3s ease-in-out;
    margin: 0 0 8px;
    &:hover {
      color: ${theme.color.tertiaryColor};
    }
  }
`

export const QrImg = styled.img`
  width: 100%;
  margin: 0 auto;
  border: 1px solid ${theme.color.primaryColor};
  border-radius: 4px;
  overflow: hidden;
  ${media.tablet`
    width: 200px;
  `}
`

export const Line = styled.hr`
  border: none;
  border-top: 1px solid ${theme.color.lightBlue};
  margin: ${(props) => props.margin || '32px 0'};
`

export default QuotationContainer
