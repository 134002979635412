import React, { useContext, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import { filter, isEmpty } from 'lodash'
import {
  Div, Icon, SmallText, Label,
} from '@konsys-ui-custom'
import { useMutation } from '@apollo/client'
import { theme } from '../../styles/variables'
import { menuItems } from '../../constants/menu-config'
import { removeAccessToken, removeRefreshToken, getURL } from '../../utils/local-data-service'
import Logo from '../../assets/logo.jpeg'
import { IDLE_ADMIN } from '../../constants/graph/mutation'
import { GlobalContext } from '../../store/StoreProvider'

const NavigateBar = ({
  subPageTitle, pageTitle, leftIcon, leftFn, rightText, rightFn, rightIcon, rightComponent,
}) => {
  const { authorization } = useContext(GlobalContext)
  const [visible, setVisible] = useState(false)

  const logout = () => {
    removeAccessToken()
    removeRefreshToken()
    window.location = `${getURL().WEB_SERVER}`
  }
  const openDrawer = () => setVisible(true)
  const closeDrawer = () => setVisible(false)

  const drawerProps = {
    placement: 'left',
    onClose: closeDrawer,
    visible,
    width: '100%',
  }

  return (
    <Div bgColor={theme?.color?.primaryColor} padding='16px' display='flex' alignItems='center'>
      <Drawer {...drawerProps}>
        <Div display='flex' justifyContent='space-between' moreStyle={{ flexDirection: 'column', height: `calc(${window.innerHeight - 48}px)` }}>
          <Div>
            {
              (authorization?.Permission?.all ? menuItems : filter(menuItems, (perm) => perm.id !== 'role'))?.map((menuDetail) => (
                <Link key={menuDetail?.id} to={menuDetail.path} activeClassName='active-menu' onClick={closeDrawer}>
                  <Div display='flex' padding='16px 0' moreStyle={{ cursor: 'pointer', borderBottom: `1px solid ${theme?.color?.gray10}` }}>
                    <Div width='24px' margin='0 8px 0 0'>
                      <Icon icon={`fal ${menuDetail?.icon}`} />
                    </Div>
                    <SmallText>{menuDetail?.name}</SmallText>
                  </Div>
                </Link>
              ))
            }
          </Div>
          <Div>
            <Div display='flex' padding='16px 0 8px' moreStyle={{ cursor: 'pointer' }} onClick={logout}>
              <Div width='24px' margin='0 8px 0 0'>
                <Icon icon='fal fa-sign-out' />
              </Div>
              <SmallText>ออกจากระบบ</SmallText>
            </Div>
            <Label color={theme?.color?.gray40}>v.1.3.0</Label>
          </Div>
        </Div>
      </Drawer>
      <Icon color={theme?.color?.offWhite} icon={leftIcon || 'fal fa-bars'} style={{ cursor: 'pointer' }} onClick={leftFn || openDrawer} />
      <Div moreStyle={{ flexGrow: 1 }} margin='0 24px' textAlign='center'>
        <Div textAlign='center'>
          {
            pageTitle
              ? <SmallText textAlign='center' display='block' color={theme?.color?.offWhite} bold>{pageTitle || 'Logo'}</SmallText>
              : <img src={Logo} height='20px' />
          }
          {
            subPageTitle
            && <Label color={theme?.color?.offWhite} style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>{subPageTitle}</Label>
          }
        </Div>
      </Div>
      {
        !isEmpty(rightText)
        && <SmallText color={theme?.color?.offWhite} textAlign='right' onClick={rightFn && rightFn} style={{ cursor: 'pointer' }}>{rightText}</SmallText>
      }
      {
        rightIcon
        && <Icon color={theme?.color?.offWhite} icon={rightIcon} style={{ cursor: 'pointer' }} onClick={rightFn && rightFn} />
      }
      {
        rightComponent && rightComponent
      }
    </Div>
  )
}

export default ({ navigateProps, children }) => {
  const [doLog] = useMutation(IDLE_ADMIN)
  // eslint-disable-next-line no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: process.env.NODE_ENV === 'production' ? 900000 : 1800000,
    onIdle: () => {
      if (process.env.NODE_ENV !== 'development') {
        doLog().then(() => {
          removeAccessToken()
          removeRefreshToken()
          window.location = `${getURL().WEB_SERVER}/login`
        })
      }
    },
    onActive: () => console.info('onActive!!'),
    onAction: () => console.info('onAction!!'),
    debounce: 500,
  })
  return (
    <Div>
      <NavigateBar {...navigateProps} />
      { children }
    </Div>
  )
}
