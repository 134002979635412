export default {
  CLIENT_ID: process.env.CLIENT_ID,
  CLIENT_SECRET: process.env.CLIENT_SECRET,
  GRANT_TYPE: process.env.GRANT_TYPE,
  GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE,
  GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC,
  FILE_SERVER: process.env.REACT_APP_FILE_SERVER,
  API_HOST: process.env.REACT_APP_FILE_SERVER,
  GRAPH_PRIVATE_WS: process.env.REACT_APP_GRAPH_PRIVATE_SUBSCRIPTION,
  WEB_SERVER: process.env.REACT_APP_WEB_SERVER,
  EPR_SERVER: process.env.REACT_APP_ERP_SERVER,
}
